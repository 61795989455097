import { ChangeDetectionStrategy, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeInOut } from '../../../utils/animations';
import { BookShelfDrawerCoverImageComponent } from './components/book-shelf-drawer-cover-image/book-shelf-drawer-cover-image.component';
import { BookShelfDrawerBeanPromptComponent } from './components/book-shelf-drawer-bean-prompt/book-shelf-drawer-bean-prompt.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SwiperDirective } from '../../../directives/swiper.directive';
import { ButtonComponent } from '../../button/button.component';
import { BooksService, ShelfType } from 'src/app/services/books.service';
import Swiper from 'swiper';
import { SWIPER_SHELF_CONFIG, SWIPER_SHELF_CONFIG_MOBILE, SWIPER_SHELF_CONFIG_TABLET } from '../../../pages/book-reader/book-reader.constants';
import { combineLatest, distinctUntilChanged, filter, skip, Subject, switchMap, take, takeUntil } from 'rxjs';
import { Book, BookCategory } from 'src/app/models/book.model';
import { AppService } from 'src/app/services/app.service';
import { LanguageState } from '../../../store/language.state';
import { NavigationService } from 'src/app/services/navigation.service';
import { LanguageService } from '../../../services/language.service';
import { AsyncPipe } from '@angular/common';
import { IonSpinner } from '@ionic/angular/standalone';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-book-shelf-drawer',
  templateUrl: './book-shelf-drawer.component.html',
  styleUrls: ['./book-shelf-drawer.component.scss'],
  animations: [fadeInOut],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [AsyncPipe, ButtonComponent, SwiperDirective, TranslateModule, BookShelfDrawerBeanPromptComponent, BookShelfDrawerCoverImageComponent, IonSpinner],
})
export class BookShelfDrawerComponent implements OnDestroy, OnInit {
  @Input({ required: true }) type!: ShelfType;
  @Input() category?: BookCategory; // Required only if ShelfType is Category

  @ViewChild(SwiperDirective, { read: ElementRef }) swiperRef!: ElementRef<{ swiper: Swiper }>;
  triggerNewAnimation = false;
  readonly shelfType = ShelfType;
  swiperConfiguration = SWIPER_SHELF_CONFIG;
  isLoadingBooks = true; // Placeholder displayed
  isLoadingNewBooks = false; // Slider displayed
  isEverythingLoaded = false;
  shelfTotalLength?: number;
  books: Book[] = [];
  title = '';
  private destroyed$ = new Subject<void>();
  private pageSize = 10;
  private currentPage = 0;

  constructor(
    public appService: AppService,
    private translateService: TranslateService,
    public booksService: BooksService,
    private ref: ChangeDetectorRef,
    private navigationService: NavigationService,
    private languageService: LanguageService,
    private languageState: LanguageState,
  ) {}

  get swiper(): Swiper | undefined {
    return this.swiperRef?.nativeElement?.swiper;
  }

  get shelfDisplayed(): boolean {
    return this.isLoadingBooks || !!this.books.length;
  }

  // We initially get the first 10 books saved in the memory.
  // If the user scroll, it'll load new books from the backend
  ngOnInit(): void {
    this.booksService.isReloading$
      .pipe(
        skip(1),
        takeUntil(this.destroyed$),
        filter(reloading => reloading),
      )
      .subscribe(() => {
        this.isLoadingBooks = true;
        this.ref.detectChanges();
      });

    this.booksService.initialized$
      .pipe(
        filter(initialized => initialized),
        take(1),
        switchMap(() => this.booksService.getBookRequest(this.type, this.category?.id)),
      )
      .subscribe({
        next: books => {
          this.books = books || [];
          this.isLoadingBooks = false;

          setTimeout(() => (this.triggerNewAnimation = true), 500);

          setTimeout(() => {
            this.swiper?.slides && this.swiper?.updateSlides();

            this.isLoadingBooks = false;
            this.ref.detectChanges();

            this.swiper &&
              this.swiper.on('slideChange', () => {
                const totalSlides = this.swiper?.slides?.length || 0;
                const slidesPerView = Number(this.swiperConfiguration.slidesPerView);
                const lastIndex = totalSlides - slidesPerView;

                // We load new books when 17/20 slides are displayed
                if (this.swiper!.activeIndex >= lastIndex - 3 && !this.isEverythingLoaded && !this.isLoadingNewBooks) {
                  this.loadBooks();
                }

                this.ref.detectChanges();
              });
          }, 1);
        },
        error: () => {
          setTimeout(() => {
            this.swiper?.slides && this.swiper?.updateSlides();
            this.isLoadingBooks = false;
            this.ref.detectChanges();
          }, 1);
        },
      });

    combineLatest([this.appService.isMobile$, this.appService.isTablet$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(([isMobile, isTablet]) => {
        if (isMobile) {
          this.swiperConfiguration = SWIPER_SHELF_CONFIG_MOBILE;
        } else if (isTablet) {
          this.swiperConfiguration = SWIPER_SHELF_CONFIG_TABLET;
        } else {
          this.swiperConfiguration = SWIPER_SHELF_CONFIG;
        }
      });

    this.languageState.language$.pipe(distinctUntilChanged((prev, curr) => prev.selected === curr.selected)).subscribe(() => {
      switch (this.type) {
        // Store features / popular & new shelves data in booksService
        case ShelfType.Featured:
          this.title = this.translateService.instant('PWA_bookShelf_BOW');
          break;
        case ShelfType.Popular:
          this.title = this.translateService.instant('PWA_bookShelf_popularAmongstReaders');
          break;
        case ShelfType.New:
          this.title = this.translateService.instant('PWA_bookShelf_newBooks');
          break;
        case ShelfType.ContinueReading:
          this.title = this.translateService.instant('PWA_bookshelf_continueReading');
          break;
        case ShelfType.Favorites:
          this.title = this.translateService.instant('myLibrary_bookShelf_myFavorites');
          break;
        case ShelfType.ReadAgain:
          this.title = this.translateService.instant('myLibrary_bookShelf_readAgain');
          break;
        case ShelfType.BedStory:
          this.title = this.translateService.instant('PWA_bookShelf_bedtimeStories');
          break;
        case ShelfType.YouMightAlsoLike:
          this.title = this.translateService.instant('PWA_bookShelf_youMightLike');
          break;
        case ShelfType.FeaturedActive:
          this.booksService.featuredActiveBooksTitle$.pipe(takeUntil(this.destroyed$)).subscribe(title => {
            title && (this.title = this.languageService.translateBackendCopy(title));
          });
          break;
        case ShelfType.Collection:
          this.booksService.collectionTitle$.pipe(takeUntil(this.destroyed$)).subscribe(title => {
            title && (this.title = this.languageService.translateBackendCopy(title));
          });

          break;
        case ShelfType.Category:
          if (!this.category?.id) {
            console.error('No category Id passed for category' + this.category?.id);
          }

          if (!this.category?.name) {
            console.error('No category Name passed for category' + this.category?.id);
            this.title = '';
          }

          this.title = this.languageService.translateBackendCopy(this.category!.name);
          break;
        default:
          this.title = '';
      }
      this.ref.detectChanges();
    });
  }

  swipeBook(next: boolean): void {
    next ? this.swiper?.slideNext() : this.swiper?.slidePrev();
  }

  goToShelf(): void {
    void this.navigationService.navigate('/shelf/' + (this.type === ShelfType.Category ? 'category/' + this.category?.id : this.type), {
      state: { title: this.category?.name ? this.languageService.translateBackendCopy(this.category.name) : '' },
    });
  }

  goToBook(): void {
    void this.navigationService.navigate(`/book/${this.books[0].uuid}/reader`);
  }

  goToHome(): void {
    void this.navigationService.navigate('home');
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private loadBooks(): void {
    this.isLoadingNewBooks = true;
    if (this.shelfDisplayed) {
      this.swiper?.update(); // Need to update the slides in order to display the loader
    }

    this.ref.detectChanges();
    this.currentPage = this.currentPage + 1;

    this.booksService
      .getPagedBooksRequest(this.type, this.category?.id, this.currentPage * this.pageSize, this.pageSize)
      .pipe(take(1))
      .subscribe(paginatedBooks => {
        const books = paginatedBooks?.books ?? [];

        this.books = this.books.concat(...books);
        this.ref.detectChanges();
        if (this.shelfDisplayed && this.books && this.books?.length > 0 && this.swiper && this.swiper.slides && this.swiper.slides.length > 0) {
          this.swiper.updateSlides();
        }

        if ((paginatedBooks?.totalCount === this.books.length || paginatedBooks?.totalCount === 0) && this.shelfDisplayed) {
          this.isEverythingLoaded = true;
          // We enable loop here.
          this.swiperConfiguration = {
            ...this.swiperConfiguration,
            loop: true,
          };
          this.swiper?.updateSlides();
        }

        this.shelfTotalLength = paginatedBooks?.totalCount;
        this.isLoadingNewBooks = false;
        this.books.length && this.swiper?.update();
        this.ref.detectChanges();
      });
  }
}
